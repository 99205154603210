/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import ReactDOM from "react-dom/client"

import React from "react"
import { Provider } from "react-redux"
import createReduxStore from "./src/redux/createStore"
import { ThemeProvider } from "styled-components"
import { GlobalStyle, theme } from "./src"
import LocaleProvider from "./src/utils/localeProvider"

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={createReduxStore()}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {element}
      </ThemeProvider>
    </Provider>
  )
}