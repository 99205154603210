import React, {useCallback, useContext, useEffect, useState} from "react"
import i18n from "../i18n"
import locales from "../../locales"
import {navigate} from "gatsby"

export const LocaleContext = React.createContext({})
export const LocationContext = React.createContext({})

export function useLocale() {
    return useContext(LocaleContext) || {locale: i18n.language || i18n.languages[0]};
}

export function useLocation() {
    return useContext(LocationContext) || {};
}

export function useRouteLocale(location, locale) {
    useEffect(() => {
        const pathSplitted = location.pathname.split("/")
        const shouldBeLocale = pathSplitted[1]
        const isLocale = locales.includes(shouldBeLocale)
        const pathWithoutLocale = isLocale
            ? [...pathSplitted.slice(0, 1), ...pathSplitted.slice(2)].join("/")
            : location.pathname

        if (locale !== shouldBeLocale) {
            navigate(
                `/${locale}${pathWithoutLocale}${location.search}${location.hash}`,
                {state: location.state, replace: true}
            )
        }
    }, [location, locale])
}

function getValidLocale(locale) {
    if (!locale) return i18n.languages[0];
    if (locales.includes(locale)) {
        return locale
    }
    let potentialLocale = locale.split("-")[0]
    if (locales.includes(potentialLocale)) {
        return potentialLocale
    }
    return i18n.languages[0]
}

// export function extractPathLocale() {
//     const regexed = window.location?.pathname?.match(/^\/([a-z]{2})\//) || undefined;
//     const pathLocale = regexed && regexed[1];
//
//     return locales.includes(pathLocale) ? pathLocale : undefined;
// }

export default function LocaleProvider({children, location}) {
    const regexed = location?.pathname?.match(/^\/([a-z]{2})\//) || undefined;
    const pathLocale = regexed && regexed[1];

    const [canSetLocaleFromPath, setCanSetLocaleFromPath] = useState(
        pathLocale !== undefined && locales.includes(pathLocale) ? pathLocale !== (i18n.language || "en") : false
    )
    const [locale, setLocale] = useState(
        getValidLocale(pathLocale || i18n.language || "en")
    )

    const handleLocale = useCallback((newLocale) => {
        if (newLocale !== locale) {
            i18n.changeLanguage(newLocale).then(r => {
                setLocale(newLocale)
            })
        }
    }, [locale]);

    useEffect(() => {
        if (canSetLocaleFromPath) {
            setCanSetLocaleFromPath(false)
            void i18n.changeLanguage(locale);
        }
    }, [canSetLocaleFromPath, locale])

    useRouteLocale(location, locale)

    return (
        <LocaleContext.Provider value={{locale, handleLocale, locales}}>
            <LocationContext.Provider value={{location}}>
                {!canSetLocaleFromPath && children}
            </LocationContext.Provider>
        </LocaleContext.Provider>
    )
}
