const INITIAL_STATE = {
  hamburgerMenuActive: false
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case "TOGGLE_HAMBURGER_MENU":
      return {
        ...state,
        hamburgerMenuActive: action.hamburgerMenuActive
      }
    default:
      return state;
  }
}