import i18n from "i18next"
import {initReactI18next} from "react-i18next"
// import LanguageDetector from "i18next-browser-languagedetector"
import moment from "moment"
import "moment/min/locales"

import textsEN from "../public/translations/en.json"
import textsLT from "../public/translations/lt.json"
import textsRU from "../public/translations/ru.json"
import {getCookieValue} from "./utils/cookie"
// import textsLV from "../public/translations/texts_lv.json"
// import textsEE from "../public/translations/texts_ee.json"
import textsPL from "../public/translations/pl.json"
// import textsBG from "../public/translations/texts_bg.json"
// import textsDK from "../public/translations/texts_dk.json"
import textsES from "../public/translations/es.json"
// import textsFI from "../public/translations/texts_fi.json"
// import textsFR from "../public/translations/texts_fr.json"
// import textsGR from "../public/translations/texts_gr.json"
// import textsIS from "../public/translations/texts_is.json"
import textsIT from "../public/translations/it.json"
// import textsNO from "../public/translations/texts_no.json"
import textsPT from "../public/translations/pt.json"
// import textsSE from "../public/translations/texts_se.json"
// import textsTR from "../public/translations/texts_tr.json"
import locales from "../locales";

const resources = {
    en: {
        texts: textsEN,
    },
    lt: {
        texts: textsLT,
    },
    ru: {
        texts: textsRU,
    },
    // ee: {
    //   texts: textsEE,
    // },
    // lv: {
    //   texts: textsLV,
    // },
    pl: {
        texts: textsPL,
    },
    // bg: {
    //   texts: textsBG,
    // },
    // dk: {
    //   texts: textsDK,
    // },
    es: {
        texts: textsES,
    },
    // fi: {
    //   texts: textsFI,
    // },
    // fr: {
    //   texts: textsFR,
    // },
    // gr: {
    //   texts: textsGR,
    // },
    // is: {
    //   texts: textsIS,
    // },
    it: {
        texts: textsIT,
    },
    // no: {
    //   texts: textsNO,
    // },
    pt: {
        texts: textsPT,
    },
    // se: {
    //   texts: textsSE,
    // },
    // tr: {
    //   texts: textsTR,
    // },
}

const currentCookie = getCookieValue()
var caches = ["localStorage"]
if (currentCookie && currentCookie.preferences) {
    caches.push("cookie")
}

i18n
    // .use(LanguageDetector)
    // .use(Backend)
    .use(initReactI18next)
    .init({
        resources,
        // lng: currentPathLocale,
        fallbackLng: "en",
        supportedLngs: locales,
        detection: {
            order: ["querystring", "cookie"],
            caches: ["cookie"]
        },
        backend: {
            loadPath: "/translations/{{ns}}_{{lng}}.json"
        },
        // lng: "en"
        debug: false,
        ns: ["texts"],
        defaultNS: "texts",
        keySeparator: false,
        // preload: [currentPathLocale || "en"],
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },
        react: {
            wait: true
        }
    }).then(() => {
    i18n.on("languageChanged", function (lng) {
        void moment.locale(lng)
    });
});

export default i18n
