const INITIAL_STATE = {
  user: undefined,
  signedIn: false,
  subscriptionModalActive: false
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case "USER_LOADED":
      return {
        ...state,
        user: action.user || {},
        signedIn: action.signedIn
      }
    case "SHOW_SUBSCRIPTION_MODAL":
      return {
        ...state,
        subscriptionModalActive: action.active
      }
    default:
      return state;
  }
}