const INITIAL_STATE = {
    loadingPaymentAccounts: true,
    paymentAccounts: undefined
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case "INIT_GET_PAYMENT_ACCOUNTS":
            return {
                ...state,
                loadingPaymentAccounts: true
            }
        case "GET_PAYMENT_ACCOUNTS":
            return {
                ...state,
                paymentAccounts: action.paymentAccounts,
                loadingPaymentAccounts: false
            }
        case "END_GET_PAYMENT_ACCOUNTS":
            return {
                ...state,
                loadingPaymentAccounts: false
            }
        default:
            return state;
    }
}