const INITIAL_STATE = {
  index: 0
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case "INDEX_CHANGED":
      return {
        ...state,
        index: action.index
      }
    default:
      return state;
  }
}