import { Cookies } from "react-cookie-consent";
import { useEffect, useState } from "react"

export const DEFAULT_COOKIE_NAME = "HotelstonConsentCookie";
export const DEFAULT_COOKIE_VALUE = {
  required: true,
  preferences: false,
  statistics: false,

  declined: false
}

export function useCookieValue() {
  const [currentCookieValue, setsCurrentCookieValue] = useState(getCookieValue() || {})

  useEffect(() => {
    setsCurrentCookieValue(currentCookieValue)
  }, [currentCookieValue]);

  return currentCookieValue;
}

export function getCookieValue() {
  const currCookie = Cookies.get(DEFAULT_COOKIE_NAME);

  return currCookie ? JSON.parse(currCookie) : undefined;
}

export function setCookieValue(newCookie) {
  Cookies.set(DEFAULT_COOKIE_NAME, newCookie);
}