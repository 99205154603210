module.exports = [
    "en",
    "lt",
    "ru",
    // "bg",
    // "ee",
    // "lv",
    "pl",
    "it",
    // "fr",
    "es",
    "pt",
    // "gr",
    // "is",
    // "dk",
    // "no",
    // "se",
    // "fi",
    // "tr",
]
