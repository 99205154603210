const INITIAL_STATE = {
  width: 0,
  height: 0
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case "DIMENSIONS_CHANGED":
      return {
        ...state,
        width: action.width,
        height: action.height
      }
    default:
      return state;
  }
}